import React, { useState, useContext, useCallback, useEffect } from 'react';
import { SocketContext } from '../context/socket';
import { useNavigate } from 'react-router-dom';
import { getTranslation } from './../lang'

import { Center } from '../components/Basic';
import Background, { BackgroundColor } from "../components/Background";
import InputBox from '../components/InputBox';
import DropdownSelect from '../components/DropdownSelect';
import SettingInput from '../components/SettingInput';
import Countdown from '../components/Countdown';
import Scoreboard from '../components/Scoreboard';
import HostErrorBoundary from '../components/ErrorBoundary';

let loadType, fileList;

// TODO: This class is just copied from the old version and made working. This is not optimized
class PlayerMenu extends React.Component {
    static contextType = SocketContext;

    constructor(props) {
        super(props);
        this.playerId = null;
        this.state = { showInfo: false };
    }

    openPlayerInfo(playerId) {
        this.playerId = playerId;
        this.setState({ showInfo: true });
    }

    makeAnswerCorrect(questionIndex, answer) {
        this.context.emit('make-answer-correct', answer, questionIndex);
    }

    render() {
        let element;

        if (this.state.showInfo) {
            let player = this.props.playerList[this.playerId];
            let answers = [];

            for (let questionIndex in player.answers) {
                answers.push({
                    question: this.props.slides[questionIndex].question,
                    question_type: this.props.slides[questionIndex].question_type,
                    questionIndex: questionIndex,
                    answer: player.answers[questionIndex].answer,
                    correctAnswer: this.props.slides[questionIndex].host_payload.answers,
                    correct: player.answers[questionIndex].correct,
                    points: player.answers[questionIndex].points,
                    maxPoints: this.props.slides[questionIndex].host_payload.points,
                });
            }

            element = (<div className="dashboard__players">
                <div className="dashboard__players__info">
                    <h2>Info</h2>
                    <p>Name: {player.name}</p>
                    <p>Answers: {Object.keys(player.answers || {}).length}</p>
                    <p>Score: {player.score}</p>
                    <button className="dashboard__players__backButton" onClick={() => this.setState({ showInfo: false })}>
                        <svg width="50" height="50" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5858 30.5858C14.8047 31.3668 14.8047 32.6332 15.5858 33.4142L28.3137 46.1421C29.0948 46.9232 30.3611 46.9232 31.1421 46.1421C31.9232 45.3611 31.9232 44.0948 31.1421 43.3137L19.8284 32L31.1421 20.6863C31.9232 19.9052 31.9232 18.6389 31.1421 17.8579C30.3611 17.0768 29.0948 17.0768 28.3137 17.8579L15.5858 30.5858ZM47 30L17 30V34H47V30Z" fill="#BEBEBE"/>
                        </svg>
                    </button>
                </div>
                <div className="dashboard__players__answers">
                    <h2>Answers</h2>
                    {(player.answers) ? (<div>
                        {answers.map((answer, i) => {
                            return (<div key={i} className="dashboard__players__answer">
                                <div>
                                    <h3>{answer.question}</h3>
                                    <b>#{Number(answer.questionIndex) + 1}</b>
                                </div>
                                <div>
                                    <p>Answer: {(answer.answer instanceof Array) ? answer.answer.map((x, i) => {return (i !== 0) ? ", " + x : x}) : answer.answer}</p>
                                    {["multiple_choice", "open"].includes(answer.question_type) && !answer.correct && 
                                        <button onClick={() => this.makeAnswerCorrect(answer.questionIndex, answer.answer)}>Make correct</button>
                                    }
                                    <p>Correct answer: {(answer.correctAnswer instanceof Array) ? answer.correctAnswer.map((x, i) => {return (i !== 0) ? ", " + x : x}) : answer.correctAnswer}</p>
                                    <p>Points earned: {answer.points || 0}</p>
                                </div>
                            </div>);
                        })}
                    </div>) : (
                        <p>Player hasn't answered any questions yet</p>
                    )}
                </div>
            </div>); 
        } 
        else {
            let players = [];
            Object.keys(this.props.playerList).forEach(id => {
                let player = this.props.playerList[id];
                player['id'] = id;
                players.push(player);
            });

            element = (<div className="dashboard__players">
                {players.map((player, i) => {
                    return (<div key={i}  className="dashboard__players__player">
                        <p>{player.name}</p>
                        <p>Score: {player.score}</p>
                        <button onClick={() => this.openPlayerInfo(player.id)}>•••</button>
                    </div>);
                })}
            </div>);
        }

        return element;
    }
}

const Dashboard = (props) => {
    const socket = useContext(SocketContext);
    const [playerList, setPlayerList] = useState({});
    const [gameState, setGameState] = useState("");
    const [slides, setSlides] = useState([]);
    const [slideIndex, setSlideIndex] = useState(-1);
    const [menuIndex, setMenuIndex] = useState(0);
    const [playersAnswered, setPlayersAnswered] = useState(0);
    const [playersNotAnswered, setPlayersNotAnswered] = useState([]);
    const [playerAnswers, setPlayerAnswers] = useState([]);
    const [answersCorrect, setAnswersCorrect] = useState(0);
    const [pauseMessage, setPauseMessage] = useState("");
    const [clock, setClock] = useState(null);
    const [scoreboard, setScoreboard] = useState([]);

    const scoreboardRef = React.createRef();

    /* -------------------------------------------------------------------------- */
    /*                                  Functions                                 */
    /* -------------------------------------------------------------------------- */
    const nextSlide = useCallback(() => {
        socket.emit('next-slide', (success) => {
            if (!success) {
                console.log("Cannot go to the next slide");
                return;
            }
            setSlideIndex(slideIndex+1);
        });
    }, [socket, slideIndex]);

    const showResults = useCallback(() => {
        socket.emit('show-results');
    }, [socket]);

    const pause = useCallback(() => {
        socket.emit('pause', pauseMessage);
    }, [socket, pauseMessage]);

    const changePauseMessage = useCallback((message) => {
        setPauseMessage(message);
        socket.emit('update-pause-message', message);
    }, [socket]);

    const showScoreboard = useCallback(() => {
        socket.emit('show-scoreboard');
    }, [socket]);

    const toggleMediaEnlarge = useCallback(() => {
        socket.emit('toggle-media-enlarge');
    }, [socket]);

    const saveGame = useCallback(() => {
        socket.emit('save-game');
    }, [socket]);

    const recheckAnswers = useCallback(() => {
        socket.emit('recheck-answers');
    }, [socket]);

    const exportCSV = useCallback(() => {
        if (!scoreboard) return;
        
        let csvData = "Player;Score\n";
        for (const player of scoreboard) {
            csvData += `${player.name};${player.score}\n`;
        }

        let hiddenAnchor = document.createElement('a');  
        hiddenAnchor.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);  
        hiddenAnchor.target = '_blank';  
        hiddenAnchor.download = 'Player scores.csv';
        hiddenAnchor.click();  
    }, [scoreboard]);

    const makeAnswerCorrect = useCallback((answer) => {
        socket.emit('make-answer-correct', answer, slideIndex);
    }, [socket, slideIndex]);

    /* -------------------------------------------------------------------------- */
    /*                                Server events                               */
    /* -------------------------------------------------------------------------- */
    const updatePlayerList = useCallback((playerListIn) => {
        setPlayerList(playerListIn);
    }, []);

    const updateState = useCallback((state) => {
        setGameState(state);
    }, []);

    const updateSlides = useCallback((slides) => {
        setSlides(slides);
    }, []);

    const updateSlideIndex = useCallback((slide) => {
        setSlideIndex(slide);
    }, []);

    const updatePlayersAnswered = useCallback((playersAnsweredIn, playersNotAnsweredIn, playerAnswersIn) => {
        setPlayersAnswered(playersAnsweredIn || 0);
        setPlayersNotAnswered(playersNotAnsweredIn || []);
        setPlayerAnswers(playerAnswersIn || []);
    }, []);

    const updateAnswersCorrect = useCallback((answersCorrectIn) => {
        setAnswersCorrect(answersCorrectIn);
    }, []);

    const updatePauseMessage = useCallback((message) => {
        setPauseMessage(message);
    }, []);

    const updateClock = useCallback((time) => {
        setClock(time);
    }, []);

    const updateScoreboard = useCallback((scoreboardIn) => {
        setScoreboard(scoreboardIn);
    }, []);

    useEffect(() => {
        socket.on("update-player-list", updatePlayerList);
        socket.on("update-state", updateState);
        socket.on("update-slides", updateSlides);
        socket.on("update-slide-index", updateSlideIndex);
        socket.on("update-players-answered", updatePlayersAnswered);
        socket.on("update-answers-correct", updateAnswersCorrect);
        socket.on("update-pause-message", updatePauseMessage);
        socket.on("update-clock", updateClock);
        socket.on("update-scoreboard", updateScoreboard);

        return () => {
            socket.off("update-player-list", updatePlayerList);
            socket.off("update-state", updateState);
            socket.off("update-slides", updateSlides);
            socket.off("update-slide-index", updateSlideIndex);
            socket.off("update-players-answered", updatePlayersAnswered);
            socket.off("update-answers-correct", updateAnswersCorrect);
            socket.off("update-pause-message", updatePauseMessage);
            socket.off("update-clock", updateClock);
            socket.off("update-scoreboard", updateScoreboard);
        };
    }, [socket, updatePlayerList, updateState, updateSlides, updateSlideIndex, updatePlayersAnswered, updateAnswersCorrect, updatePauseMessage, updateClock, updateScoreboard]);

    /* -------------------------------------------------------------------------- */
    /*                                 Return DOM                                 */
    /* -------------------------------------------------------------------------- */
    const menuItems = ["main", "scoreboard", "players", "settings"];

    let mainElem;
    switch (menuIndex) {
        case 0:
            mainElem = (<div>
                <div className="dashboard__main">
                    <div className="dashboard__main__info">
                        <p>{getTranslation("dashboard.info.players")}: {Object.keys(playerList).length}</p>
                        <p>{getTranslation("dashboard.info.answers")}: {(slides?.[slideIndex]?.type === "question") ? playersAnswered : "/"}</p>
                        <p>{getTranslation("dashboard.info.correct_answers")}: {(gameState === "result") ? answersCorrect : "/"}</p>
                        <p>{getTranslation("dashboard.info.time_limit")}: {(slides?.[slideIndex]?.type === "question" && slides[slideIndex].host_payload.time_limit) ? slides[slideIndex].host_payload.time_limit + " sec." : "/"}</p>
                        <p>{getTranslation("dashboard.info.points")}: {(slides?.[slideIndex]?.type === "question") ? slides[slideIndex].host_payload.points : "/"}</p>
                    </div>
                    <div className="dashboard__main__nAnswered">
                        <p>{playersNotAnswered.length} {
                            (playersNotAnswered.length === 1) ? 
                            getTranslation("dashboard.not_answered.title.singular") : 
                            getTranslation("dashboard.not_answered.title.plural")
                        }</p>
                        <div>
                            <ul>
                                {playersNotAnswered.map((name, i) => {
                                    return <li key={i}>{name}</li>    
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="dashboard__main__time">
                        {clock && <Countdown timeLimit={clock.timeLimit} startTime={clock.startTime} />}
                    </div>
                    <div className="dashboard__main__description">
                        <p>{slides?.[slideIndex]?.host_payload?.description || "No description"}</p>
                    </div>
                    <div className="dashboard__main__answers">
                        <div>
                            {(slides?.[slideIndex]?.type === "question" && ["multiple_choice", "open"].includes(slides?.[slideIndex]?.question_type)) ? (
                                playerAnswers.map((answer, i) => {
                                    const correct = slides[slideIndex].host_payload.answers.includes(answer);
                                    return <p key={i} className={"dashboard__main__answers__answer" + (correct ? " dashboard__main__answers__answer--correct" : "")} >
                                        {answer}
                                        {correct ? "" : <button onClick={() => makeAnswerCorrect(answer)}>Corr?</button>}
                                    </p>
                                })
                            ) : ""}
                        </div>
                    </div>
                    <div className="dashboard__main__mediaControls">
                        {(slides?.[slideIndex]?.media?.type === "audio") && <>
                            <audio controls autoPlay>
                                <source src={slides[slideIndex].media.src} type={slides[slideIndex].media.mimeType} />
                            </audio>
                        </>}
                    </div>
                </div>
                <div className="dashboard__functions">
                    <h2>Functions</h2>
                    <div className="dashboard__functions__grid">
                        <button 
                            className="dashboard__functions__button" 
                            onClick={nextSlide} title={getTranslation(`dashboard.buttons.${(slideIndex===-1)?"first":"next"}_slide`)} 
                            disabled={gameState === "pause" || (gameState === "slide" && slides[slideIndex]?.type === "question") || slideIndex >= slides.length-1}
                        ><img src='/assets/right-arrow-icon.svg' alt='right arrow' /></button>
                        <button 
                            className="dashboard__functions__button" 
                            onClick={showResults} title={getTranslation("dashboard.buttons.check_answers")} 
                            disabled={!(gameState === "slide" && slides?.[slideIndex]?.type === "question")}
                        ><img src='/assets/checkmark-icon.svg' alt='checkmark' /></button>
                        <button 
                            className="dashboard__functions__button" 
                            onClick={pause} title={getTranslation("dashboard.buttons.pause")} 
                            disabled={gameState === "lobby" || gameState === "scoreboard" || (gameState === "slide" && slides[slideIndex]?.type === "question")}
                        ><img src='/assets/pause-icon.svg' alt='coffee cup' /></button>
                        <button 
                            className="dashboard__functions__button" 
                            onClick={showScoreboard} title={getTranslation("dashboard.buttons.scoreboard")} 
                            disabled={gameState === "lobby" || gameState === "pause" || (gameState === "slide" && slides[slideIndex]?.type === "question")}
                        ><img src='/assets/scoreboard-icon.svg' alt='scoreboard' /></button>
                        <button 
                            className="dashboard__functions__button" 
                            onClick={toggleMediaEnlarge} title={getTranslation("dashboard.buttons.media_enlarge")} 
                            disabled={gameState !== "slide" || slides[slideIndex]?.type !== "question" || !slides[slideIndex]?.media}
                        ><img src='/assets/enlarge-icon.svg' alt='enlarge media' /></button>
                    </div>
                    <div className="dashboard__functions__extra">
                        <button 
                            className="dashboard__functions__button" 
                            onClick={props.addGame} title={getTranslation("dashboard.buttons.add_game")} 
                        ><img src='/assets/add-game-icon.svg' alt='file icon with plus sign' /></button>
                        <button 
                            className="dashboard__functions__button" 
                            onClick={saveGame} title={getTranslation("dashboard.buttons.save_game")} 
                        ><img src='/assets/save-icon.svg' alt='save icon' /></button>
                        <button 
                            className="dashboard__functions__button" 
                            onClick={recheckAnswers} title={getTranslation("dashboard.buttons.recheck_answers")} 
                        ><img src='/assets/recheck-answers-icon.svg' alt='recheck answers icon' /></button>
                        <button 
                            className="dashboard__functions__button" 
                            onClick={exportCSV} title={getTranslation("dashboard.buttons.export_csv")} 
                        ><img src='/assets/export-csv-icon.svg' alt='export svg' /></button>
                    </div>
                </div>
            </div>);
            break;
        case 1:
            mainElem = (<div className="dashboard__scoreboard">
                <h2>Scoreboard</h2>
                <Scoreboard ref={scoreboardRef} scoreboard={scoreboard}/>
            </div>);
            break;
        case 2:
            mainElem = <PlayerMenu playerList={playerList} slides={slides} />;
            break;
        case 3:
            mainElem = (<div className="dashboard__settings">
                <div className="dashboard__settings__setting">
                    <label htmlFor="pauseMsg">Pause Message</label>
                    <SettingInput 
                        name="pauseMsg"
                        defaultValue={pauseMessage} 
                        buttonText={getTranslation("dashboard.buttons.save")}
                        callback={changePauseMessage}
                    />
                </div>
            </div>);
            break;
        default:
            mainElem = (<h3>{getTranslation("messages.error.unknown")}</h3>)
            break;
    }

    return (<div className="dashboard">
        <BackgroundColor color="#E6F1DD" />
        <div className="dashboard__container">
            <div className="dashboard__topBar">
                <img src="/assets/logo.svg" alt="logo"></img>
                <p id="game-state">{gameState + ((gameState === "slide") ? `: ${slides[slideIndex]?.type}` : "")}</p>
                <div className="dashboard__topBar__slideIndex">
                    <p id="slide-index">{slideIndex+1}/{slides.length}</p>
                </div>
            </div>
            <div className="dashboard__sideNav">
                <ul>                    
                    {menuItems.map((name, i) => {
                        return <li 
                            key={i}
                            className={(menuIndex === i) ? "active" : ""}
                            onClick={() => setMenuIndex(i)} 
                        >
                            <img src={`/assets/${name}-nav-icon.svg`} alt={name} />
                        </li>;
                    })}
                </ul>
            </div>
            {mainElem}
        </div>
    </div>);
};

const Host = (props) => {
    const socket = useContext(SocketContext);

    const [appState, setAppState] = useState(0);
    const navigate = useNavigate();

    /* -------------------------------------------------------------------------- */
    /*                                  Functions                                 */
    /* -------------------------------------------------------------------------- */
    const authenticate = useCallback((pin) => {
        if(pin == null) return;
        socket.emit('authenticate', Number(pin), (isHost, alreadyStarted) => {
            if (isHost) {
                if (alreadyStarted) {
                    setAppState(3);
                    socket.emit('get-update-all');
                }
                else setAppState(1);
            };
        });
    }, [socket]);

    function getFileList(type) {
        socket.emit('get-file-list', type, (fileListIn) => {
            fileList = fileListIn;
            loadType = type;
            setAppState(2);
        });
    }

    function startQuiz(fileName) {
        if (!fileName) return;
        socket.emit('start-quiz', loadType, fileName, (success) => {
            if (!success) return;

            setAppState(3);
            socket.emit('get-update-all');
        });
    }

    function addGame() {
        socket.emit('get-file-list', "new", (fileListIn) => {
            fileList = fileListIn;
            setAppState(4);
        });
    }

    function sendAddGame(fileName) {
        if (!fileName) return;
        socket.emit('add-game', fileName, (success) => {
            if (!success) return;

            setAppState(3);
            socket.emit('get-update-all');
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                                Server events                               */
    /* -------------------------------------------------------------------------- */
    const connect = useCallback(() => {
        socket.emit('setRole', "host");
    }, [socket]);

    const disconnect = useCallback(() => {
        // navigate("/host");
        navigate("/");
    }, [navigate]);

    useEffect(() => {
        socket.on("connect", connect);
        socket.on("disconnect", disconnect);

        return () => {
            socket.removeAllListeners();
        };
    }, [socket, connect, disconnect]);

    /* -------------------------------------------------------------------------- */
    /*                                 Return DOM                                 */
    /* -------------------------------------------------------------------------- */
    let element;
    switch(appState) {
        case 0:
            element = <InputBox type="number" title="PINCODE" onEnter={authenticate} maxLength={6} autoSelect />;
            break;
        case 1:
            element = (
                <div>
                    <button className='image' onClick={() => getFileList("new")}>{getTranslation("buttons.host.new_game")}</button>
                    <button className='image' onClick={() => getFileList("load")}>{getTranslation("buttons.host.load_game")}</button>
                </div>
            );
            break;
        case 2:
            element = <DropdownSelect title={getTranslation(`messages.host.${loadType}`)} options={fileList} onEnter={startQuiz}/>;
            break;
        case 3:
            element = (
                <HostErrorBoundary socket={socket}>
                    <Dashboard addGame={addGame}></Dashboard>
                </HostErrorBoundary>
            );
            break;
        case 4:
            element = <div><BackgroundColor color={null} /><DropdownSelect title={getTranslation(`messages.host.add_game`)} options={fileList} onEnter={sendAddGame}/></div>;
            break;
        default:
            element = (<h3>{getTranslation("messages.error.unknown")}</h3>)
    }

    return <Background><Center>{element}</Center></Background>;
};

export default Host;