import React from 'react';

export default class Countdown extends React.Component {
    constructor(props) {
        super(props);
        this.startOffset = (new Date() - new Date(this.props.startTime)) / 1000;
        
        this.state = {
            timeLeft: Math.round(this.props.timeLimit - this.startOffset),
            strokeVisible: true,
            strokeDasharray: "283 283",
            timeText: ""
        };
    }

    componentDidMount() {
        this.tick()
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() { clearInterval(this.timerID); }

    tick() {
        this.setState({
            timeLeft: this.state.timeLeft - 1,
            strokeDasharray: `${(this.calculateTimeFraction() * 283).toFixed(0)} 283`,
            timeText: this.formatTimeLeft()
        });

        if (this.state.timeLeft <= 0) {
            this.setState({ strokeVisible: false });
            clearInterval(this.timerID);
        }
    }

    formatTimeLeft() {
        const time = this.state.timeLeft;
        const minutes = Math.floor(time / 60);
        let seconds = time % 60;

        if (seconds < 10) {
            seconds = `0${seconds}`;
        }

        return `${minutes}:${seconds}`;
    }

    calculateTimeFraction() {
        const rawTimeFraction = this.state.timeLeft / this.props.timeLimit;
        return rawTimeFraction - (1 / this.props.timeLimit) * (1 - rawTimeFraction);
    }

    render() {
        switch (this.props.type) {
            case 'sidebar':
                return (
                    <div className="countdown countdown--sidebar" >
                        <div className="countdown__sidebar" style={{ animationDuration: `${this.props.timeLimit}s`, animationDelay: `-${this.startOffset}s` }}></div>
                        <span className="countdown__label">
                            {this.state.timeText}
                        </span>
                    </div>
                );
            default:
                return (
                    <div className="countdown" >
                        <svg className="countdown__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <g className="countdown__stroke">
                                {(this.state.strokeVisible) ? (
                                    <path
                                        strokeDasharray={this.state.strokeDasharray}
                                        className="countdown__path-remaining"
                                        d="
                                            M 50, 50
                                            m -45, 0
                                            a 45,45 0 1,0 90,0
                                            a 45,45 0 1,0 -90,0
                                        "
                                    ></path>
                                ) : ""}
                            </g>
                        </svg>
                        <span className="countdown__label">
                            {this.state.timeText}
                        </span>
                    </div>
                );
        }
    }
}