import React from 'react';
import Countdown from './Countdown';
import { TitleSlide, MultipleChoice as PlayerMultipleChoice, Puzzle as PlayerPuzzle } from './PlayerSlide';

export default class ScreenSlide extends React.Component {
    render() {
        switch (this.props.slide.type) {
            case "title":
                return <TitleSlide slide={this.props.slide} />;  
            case "question":
                switch (this.props.slide.question_type) {
                    case "multiple_choice":
                        return <MultipleChoice slide={this.props.slide} enlargeMedia={this.props.enlargeMedia} />
                    case "correct_order":
                        return <CorrectOrder slide={this.props.slide} enlargeMedia={this.props.enlargeMedia} />   
                    case "open":
                        return <OpenQuestion slide={this.props.slide} enlargeMedia={this.props.enlargeMedia} />  
                    case "puzzle":
                        return <Puzzle slide={this.props.slide} />
                    default:
                        return (<h2>Some error occurred</h2>);
                }  
            default:
                return (<h2>Some error occurred</h2>);
        }
    }
}

class QuestionHead extends React.Component {   
    render() {        
        return (this.props.slide.showResult) ? (
            <div className="questionHead questionHead__result">
                <div className="questionHead__result__correct">
                    <h2>{this.props.slide.answersCorrect}</h2>
                    <svg viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M92 171.5L132.115 222.711C134.537 225.803 139.278 225.61 141.441 222.331L228.083 91" stroke="white" strokeWidth="20.4508" strokeLinecap="round"/>
                    </svg>
                </div>
                <div className="questionHead__result__incorrect">
                    <h2>{this.props.slide.answersIncorrect}</h2>
                    <svg viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M92 93L227 228M227 93L92 228" stroke="white" strokeWidth="20.45" strokeLinecap="round"/>
                    </svg>
                </div>
            </div>
        ) : (
            <div className="questionHead questionHead__question">
                <h1 style={{top: (this.props.slide.media) ? "-10px" : "50%"}}>{this.props.slide.question}</h1>
                {(this.props.slide.media && !this.props.hideMedia) && <QuestionMedia media={this.props.slide.media} enlargeMedia={this.props.enlargeMedia} />}
                {this.props.slide.time_limit && <Countdown timeLimit={this.props.slide.time_limit} startTime={this.props.slide.start_time} type="sidebar" />}
            </div>
        );
    }
}

class QuestionMedia extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     enLargeMedia: false
        // }

        if (this.props.media.type === "image") {
            this.src = this.props.media.src;
        }
        else {
            if (this.props.media.coverImage) this.src = this.props.media.coverImage;
            else this.src = "/images/9905690d-5f50-4591-91ba-a310db73f894.png";
        }
    }

    // toggleLargeImage = () => {
    //     this.setState({enLargeMedia: !this.state.enLargeMedia});
    // }

    render() {
        return <>
            <div 
                className={"questionHead__image" + (this.props.enlargeMedia ? " questionHead__image--large" : "")}
                // onClick={this.toggleLargeImage}
                // style={{backgroundImage: `url("${this.props.media}")`}}
            >
                <img src={this.src} alt="Question Media"/>
            </div>
        </>;
    }

    // render() {
    //     return <>
    //         {(this.props.hideImage !== true && !this.state.enLargeImage) && <div 
    //             className="questionHead__image"
    //             onClick={this.toggleLargeImage}
    //             style={{backgroundImage: `url("${this.props.media}")`}}
    //         ></div>}
    //         {(this.props.hideImage !== true && this.state.enLargeImage) && 
    //             <div 
    //                 className="questionHead__image__large" 
    //                 onClick={this.toggleLargeImage}
    //             >
    //                 <img 
    //                     src={this.props.media}
    //                     alt="Question"
    //             /></div>
    //         }
    //     </>;
    // }
}

class MultipleChoice extends React.Component {
    render() {
        return (<div className="multipleChoice">
            <QuestionHead slide={this.props.slide} enlargeMedia={this.props.enlargeMedia} />
            <PlayerMultipleChoice slide={this.props.slide} onAnswer={()=>{}} />
        </div>)
    }
}

class CorrectOrder extends React.Component {
    render() {
        return (<div className="correctOrder correctOrder__screen"> 
            <QuestionHead slide={this.props.slide} enlargeMedia={this.props.enlargeMedia} />
            <div className="correctOrder__container">
                {(this.props.slide.answers || this.props.slide.options).map((option, i) => {
                    return <div key={i} className="sortable">
                        <img src="/assets/draggable-handle.svg" alt="handle"></img>
                        <p>{option}</p>
                    </div>;
                })}
            </div>
        </div>);
    }
}

class OpenQuestion extends React.Component {
    render() {
        return (<div className="openQuestion openQuestion__screen">
            <QuestionHead slide={this.props.slide} enlargeMedia={this.props.enlargeMedia} />
            {this.props.slide.answers && (
                <div className="openQuestion__answers">
                    {this.props.slide.answers.map((answer, i) => {
                        return <p key={i}>{answer}</p>
                    })}
                </div>
            )}
        </div>)
    }
}

class Puzzle extends React.Component {
    render() {
        return (<div className="puzzleQuestion puzzleQuestion__screen">
            <QuestionHead slide={this.props.slide} hideMedia={true} />
            {(this.props.slide.showResult) ? (
                <div 
                    className="puzzleQuestion__screen__image"
                    style={{backgroundImage: `url("${this.props.slide.image}")`}}
                ></div>
            ) : (
                <PlayerPuzzle slide={{screen: true, ...this.props.slide}} onAnswer={()=>{}} />
            )}
        </div>)
    }
}
