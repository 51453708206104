import React from 'react';
import { BackgroundColor } from './Background';
// import Sortable from 'sortablejs';
import { ReactSortable } from "react-sortablejs";
import { getTranslation } from '../lang';

export default class PlayerSlide extends React.Component {
    render() {
        switch (this.props.slide.type) {
            case "title":
                return <TitleSlide slide={this.props.slide} />;  
            case "question":
                switch (this.props.slide.question_type) {
                    case "multiple_choice":
                        return <MultipleChoice slide={this.props.slide} onAnswer={this.props.onAnswer} />
                    case "correct_order":
                        return <CorrectOrder slide={this.props.slide} onAnswer={this.props.onAnswer} />
                    case "open":
                        return <OpenQuestion slide={this.props.slide} onAnswer={this.props.onAnswer} />
                    case "puzzle":
                        return <Puzzle slide={this.props.slide} onAnswer={this.props.onAnswer} />
                    default:
                        return (<h2>Some error occurred</h2>);
                }  
            default:
                return (<h2>Some error occurred</h2>);
        }
    }
}

export class TitleSlide extends React.Component {
    render() {
        return (<div className="titleSlide">
            <BackgroundColor color={this.props.slide.color} />
            <img src={this.props.slide.media.src} alt="" />
            <h1 style={{"backgroundColor": this.props.slide.color}}>{this.props.slide.title}</h1>
        </div>)
    }
}

export class MultipleChoice extends React.Component {
    render() {
        return (<div className="multipleChoice__buttons">
            {this.props.slide.options.map((option, i) => {
                const answer = {
                    type: this.props.slide.question_type,
                    answer: option
                }

                return <button 
                    key={i} 
                    className="multipleChoice__button" 
                    onClick={() => this.props.onAnswer(answer)}
                    disabled={(this.props.slide.showResult && !this.props.slide.answers.includes(option)) || (this.props.slide.removeOptions && !this.props.slide.showResult &&  this.props.slide.removeOptions.includes(option))}
                >{option}</button>
            })}
        </div>)
    }
}

export class CorrectOrder extends React.Component {
    constructor(props) {
        super(props);
        this.question = this.props.slide;
        this.state = {
            options: this.question.options
        };
    }

    submit = () => {
        this.props.onAnswer({
            type: this.question.question_type,
            answer: this.state.options
        });
    }

    // ReactSortable doesn't work on mobile in React StrictMode. It does work in production
    render() {
        return (<div className="correctOrder">
            <ReactSortable
                className="correctOrder__container"
                animation={150}
                list={this.state.options}
                setList={list => this.setState({ options: list })}
            >
                {this.state.options.map((option, i) => {
                    return <div key={option} className="sortable">
                        <img src="/assets/draggable-handle.svg" alt="handle"></img>
                        <p>{option}</p>
                    </div>;
                })}
            </ReactSortable>
            <button onClick={this.submit}>{getTranslation("buttons.player.submit")}</button>
        </div>);
    }
}

// export class CorrectOrder extends React.Component {
//     constructor(props) {
//         super(props);
//         this.question = this.props.slide;
//         this.dragContainer = React.createRef();
//     }

//     componentDidMount() {
//         new Sortable(this.dragContainer.current, { 
//             animation: 150
//         });

//         // needed for IOS devices on Safari browser
//         let listToDisable = this.dragContainer.current.querySelectorAll('.sortable');
//         if (listToDisable) {
//             listToDisable.forEach(elem => {
//                 elem.addEventListener('touchstart', (e) => {
//                     e.preventDefault();
//                 })
//             })
//         }
//     }

//     submit = () => {
//         let answer = {
//             type: this.question.question_type,
//             answer: Array(this.question.options.length).fill().map((_, i) => {
//                 const index = this.dragContainer.current.children[i].getAttribute("data-id");
//                 return this.question.options[index];
//             }),
//         }

//         this.props.onAnswer(answer);
//     }

//     render() {
//         return (<div className="correctOrder">
//             <div className="correctOrder__container" ref={this.dragContainer}>
//                 {this.question.options.map((option, i) => {
//                     return <div key={i} data-id={i} className="sortable">
//                         <img src="/assets/draggable-handle.svg" alt="handle"></img>
//                         <p>{option}</p>
//                     </div>;
//                 })}
//             </div>
//             <button onClick={this.submit}>{getTranslation("buttons.player.submit")}</button>
//         </div>);
//     }
// }

export class OpenQuestion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            answer: ""
        }
    }
    
    submit = () => {
        let answer = {
            type: this.props.slide.question_type,
            answer: this.state.answer.trim(),
        }

        this.props.onAnswer(answer);
    }

    onChange = (evt) => {
        this.setState({
            answer: evt.target.value
        })
    }

    checkEnterKeyPress = (event) => {
        if (event.charCode === 13) { this.submit(); return; }
    }   

    render() {
        return (<div className="openQuestion">
            <h2>{this.props.slide.question}</h2>
            <input type="text" onChange={this.onChange} onKeyPress={this.checkEnterKeyPress} autoFocus/>
            <button onClick={this.submit}>{getTranslation("buttons.player.submit")}</button>
        </div>);
    }
}

export class Puzzle extends React.Component {
    constructor(props) {
        super(props);

        let puzzle = this._setPuzzle();
        this._setSize();
        
        this.state = {
            puzzle
        }
        this.firstPiece = null;
    }

    _setPuzzle() {
        this.puzzle = [];
        this.props.slide.puzzle.forEach(arr => this.puzzle = this.puzzle.concat(arr));

        return Array.from(Array(this.puzzle.length).keys());
    }

    _setSize() {
        let aspect_ratio = this.props.slide.aspect_ratio;
        let width = window.innerWidth - 80, height = window.innerHeight - 160;

        if (this.props.slide.screen)
            width = window.innerWidth * 0.7; height = window.innerHeight * 0.7;

        if (width / aspect_ratio > height) width = height * aspect_ratio;
        else height = width / aspect_ratio;
        
        this.size = {
            puzzle: {
                width: width,
                height: height
            },
            piece: {
                width: width / this.props.slide.puzzle[0].length,
                height: height / this.props.slide.puzzle.length
            }
        }
    }
    
    submit = () => {
        let answer = {
            type: this.props.slide.question_type,
            answer: this.state.puzzle,
        }

        this.props.onAnswer(answer);
    }

    swap = (index, evt) => {
        if (this.firstPiece != null) {

            let arr = this.state.puzzle;
            [arr[index], arr[this.firstPiece.index]] = [arr[this.firstPiece.index], arr[index]];
            this.setState({puzzle: arr});

            this.firstPiece.elem.className = "";
            this.firstPiece = null;
        }
        else {
            this.firstPiece = { index, elem: evt.target };
            evt.target.className = "puzzleQuestion__piece__outline";
        }
    }

    render() {
        return (<div className="puzzleQuestion">
            <div className="puzzleQuestion__puzzle" style={{gridTemplateColumns: `repeat(${this.props.slide.puzzle[0].length}, 1fr)`, ...this.size.puzzle}}>
                {this.state.puzzle.map((pieceIndex, index) => {
                    let x = this.puzzle[pieceIndex][0], y = this.puzzle[pieceIndex][1];

                    return <div key={index} onClick={(evt) => this.swap(index, evt)} style={{
                        backgroundImage: `url(${this.props.slide.image})`,
                        backgroundSize: `${this.size.puzzle.width}px ${this.size.puzzle.height}px`,
                        backgroundPosition: `${this.size.piece.width * x}px ${this.size.piece.height * y}px`,
                        ...this.size.piece
                    }}></div>
                })}
            </div>
            {(this.props.slide.screen !== true) && <button onClick={this.submit}>{getTranslation("buttons.player.submit")}</button>}
        </div>);
    }
}