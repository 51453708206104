const lang_file = require('./lang/en_uk.json');

/**
 * Use this function to get the translation
 * @param {string} translationKey - Key to get the translation
 * @param {string} values - Values to format into translated text at %s
 * @returns {string} Returns the translation
 */
 export function getTranslation(translationKey, ...values) {
    let i = -1;
    return lang_file[translationKey].replaceAll('%s', () => {
        i++;
        return values[i] || '%s';
    });
}