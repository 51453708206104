import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { SocketContext } from '../context/socket';
import {getTranslation} from './../lang'

import { Center, QRCode } from '../components/Basic';
import Background from "../components/Background";
import ScreenSlide from '../components/ScreenSlide';
import Scoreboard from '../components/Scoreboard';

const Screen = (props) => {
    const socket = useContext(SocketContext);
    const lobbyCenter = useRef(null);

    const [appState, setAppState] = useState(0);
    const [pauseMessage, setPauseMessage] = useState("");
    const [names, setNames] = useState([]);
    const [slide, setSlide] = useState({});
    const [oldScoreboard, setOldScoreboard] = useState([]);
    const [newScoreboard, setNewScoreboard] = useState([]);
    const [enlargeMedia, setEnlargeMedia] = useState(false);

    /* -------------------------------------------------------------------------- */
    /*                                  Functions                                 */
    /* -------------------------------------------------------------------------- */
    function changeLobbyCenterPos() {
        if (!lobbyCenter.current) return;

        const columnStart = Math.round(window.innerWidth/150 / 2 -1);
        const rowStart = Math.floor(window.innerHeight/100 / 2 -1);

        lobbyCenter.current.style = `grid-column: ${columnStart}/${columnStart + 3}; grid-row: ${rowStart}/${rowStart + 4};`;
    }
    window.onresize = changeLobbyCenterPos;

    /* -------------------------------------------------------------------------- */
    /*                                Server events                               */
    /* -------------------------------------------------------------------------- */
    const connect = useCallback(() => {
        socket.emit('setRole', "screen");
    }, [socket]);

    const showName = useCallback((name) => {
        if (appState === 2) {
            setNames([ name, ...names ]);
        }
    }, [appState, names]);

    const gameStarted = useCallback(() => {
        setAppState(2);
    }, []);

    const setState = useCallback((data) => {
        setEnlargeMedia(false);
        switch (data.state) {
            case 'nys':
                setAppState(1);
                break;
            case 'lobby':
                setAppState(2);
                setNames(data.names);
                break;
            case 'pause':
                setPauseMessage(data.message);
                setAppState(3);
                break;
            case 'slide':
                setSlide(data.slide);
                setAppState(4);
                break;
            case 'result':
                setSlide(data.slide);
                setAppState(4);
                break;
            case 'scoreboard':
                setOldScoreboard(data.oldScoreboard);
                setNewScoreboard(data.newScoreboard);
                setAppState(5);
                break;
            default:
                setAppState(-1);
        }
    }, []);

    const removeOptions = useCallback((removeOptions) => {
        let _slide = structuredClone(slide);
        _slide.removeOptions = removeOptions;
        setSlide(_slide);
    }, [slide]);

    const toggleMediaEnlarge = useCallback(() => {
        setEnlargeMedia(!enlargeMedia);
    }, [enlargeMedia, setEnlargeMedia]);

    useEffect(() => {
        socket.on("connect", connect);
        socket.on("show-name", showName);
        socket.on("game-started", gameStarted);
        socket.on("remove-options", removeOptions);
        socket.on("set-state", setState);
        socket.on("toggle-media-enlarge", toggleMediaEnlarge);

        return () => {
            socket.removeAllListeners();
        };
    }, [socket, connect, showName, gameStarted, setState, removeOptions, toggleMediaEnlarge]);

    /* -------------------------------------------------------------------------- */
    /*                                 Return DOM                                 */
    /* -------------------------------------------------------------------------- */
    let element;
    switch(appState) {
        case 0: element = ""; break;
        case 1:
            element = <h1>{getTranslation("messages.general.no_games")}</h1>;
            break;
        case 2:
            element = (<div className="screen__lobby">
                <div className="screen__lobby__center" ref={lobbyCenter} onLoad={changeLobbyCenterPos}>
                    <center>
                        <p>{window.location.host}</p>
                        <QRCode size="250" data={"http://"+window.location.host} />
                    </center>
                </div>
                {names.map((name, i) => {
                    return <div key={i} className="screen__lobby__name" ><p>{name}</p></div>;
                })}
            </div>);
            break;
        case 3:
            element = <h1>{pauseMessage}</h1>;
            break;
        case 4:
            element = <ScreenSlide slide={slide} enlargeMedia={enlargeMedia} />;
            break;
        case 5:
            element = (<div className="screen screen__scoreboard">
                <Scoreboard newScoreboard={newScoreboard} scoreboard={oldScoreboard} delay={500} />
            </div>);
            break;
        default:
            element = (<h3>{getTranslation("messages.error.unknown")}</h3>)
    }

    return <Background><Center>{element}</Center></Background>;
};

export default Screen;