import React from 'react';

export default class SettingInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = { value: this.props.defaultValue };
    }
    
    handelChange = (evt) => {
        this.setState({ value: evt.target.value });
    }

    handleClick = () => {
        this.props.callback(this.state.value);
    }

    render() {
        return (
            <div>
                <input 
                    type="text" 
                    onChange={this.handelChange} 
                    name={this.props.name}
                    defaultValue={this.props.defaultValue}
                />
                <button 
                    onClick={this.handleClick}
                >{this.props.buttonText}</button>
            </div>
        );
    }
}