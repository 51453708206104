import React, { useEffect, useState } from 'react';
import { BackgroundContext } from '../context/background';
import MetaTags from 'react-meta-tags';

export default function Background(props) {
    const [bgColorCount, setBgColorCount] = useState(0);

    useEffect(() => {
        const bgCounter = setInterval(() => {
            if (bgColorCount >= 360)
                setBgColorCount(0);
            setBgColorCount(bgColorCount + 1);
        }, 300);

        return () => clearInterval(bgCounter);
    });

    return (
        <BackgroundContext.Consumer>
            {({color}) => (
                <div
                    className='fullSize'
                    style={{ backgroundColor: color || `hsl(${bgColorCount}, 61%, 67%)` }}
                >
                    <MetaTags>
                        <meta name="theme-color" content={color || `hsl(${bgColorCount}, 61%, 67%)`} />
                    </MetaTags>
                    {props.children}
                </div>
            )}
        </BackgroundContext.Consumer>
    );
}

export class BackgroundColor extends React.Component {
    componentDidMount() { this.context.changeColor(this.props.color); }
    componentWillUnmount() {this.context.changeColor(null);}

    render() { return null }
}
BackgroundColor.contextType = BackgroundContext;