// Libraries
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Contexts
import { SocketContext, socket } from './context/socket';
import { BackgroundContext } from './context/background';

// Assets
import './index.css';
import Player from "./pages/Player";
import Screen from "./pages/Screen";
import Host from "./pages/Host";
import Test from "./pages/Test";

/* -------------------------------------------------------------------------- */
/*                                 Create DOM                                 */
/* -------------------------------------------------------------------------- */
export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.changeColor = (color) => { 
            this.setState({ color: color });
        };

        this.state = {
            color: null,
            changeColor: this.changeColor
        };
    }

    render() {
        return (
            <React.StrictMode>
                <SocketContext.Provider value={socket}>
                    <BackgroundContext.Provider value={this.state}>
                        <BrowserRouter>
                            <Routes>
                                <Route exact path="*" element={<Player />} />
                                <Route exact path="test" element={<Test />} />
                                <Route exact path="screen" element={<Screen />} />
                                <Route exact path="host" element={<Host />} />
                            </Routes>
                        </BrowserRouter>
                    </BackgroundContext.Provider>
                </SocketContext.Provider>
            </React.StrictMode>
        );
    }
}

/* -------------------------------------------------------------------------- */
/*                                 Render DOM                                 */
/* -------------------------------------------------------------------------- */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);