import React from 'react';
import {getTranslation} from './../lang'

/**
 * An input box
 * @param {string} props.title - The title of the input field
 * @param {string} props.type - The type of the input field
 * @param {Function} props.onEnter - Callback function when clicked on enter
 * @param {number} props.maxLength - The maxLength of the input field
 * @param {boolean} props.autoFocus - False to disable autofocus - True by default
 * @param {boolean} props.autoSelect - True to auto select text when focused
 */
export default class InputBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: null };
    }

    setValue = (event) => {
        this.setState({value: event.target.value});
    }

    click = () => {
        this.props.onEnter(this.state.value);
    }

    checkEnterKeyPress = (event) => {
        const value = event.target.value;
        
        // Prevent any more characters if limit is reached
        if (this.props.maxLength && this.props.type === "number") {
            if (value.length >= this.props.maxLength && document.getSelection().toString() !== value) event.preventDefault(false);
        }

        // Check if enter function need to be called
        if (event.charCode === 13) { this.click(); return; }
    }   

    onFocus = (event) => {
        if (this.props.autoSelect) event.target.select();
    }

    render() {
        const name = `inputBox-${this.props.title}`;
        return (
            <div className="inputBox">
                <label htmlFor={name}>{this.props.title}</label>
                <input 
                    type={this.props.type} 
                    pattern={(this.props.type === "number" ? "\\d*":null)} 
                    name={name} 
                    onKeyPress={this.checkEnterKeyPress}
                    onChange={ this.setValue }
                    onFocus={this.onFocus}
                    autoFocus={this.props.autoFocus || true}
                    maxLength={this.props.maxLength}
                ></input>
                <button onClick={ this.click }>{getTranslation("input_box.enter")}</button>
            </div>
        );
    }
}