import React from 'react';
import {getTranslation} from './../lang'

/**
 * An dropdown select
 * @param {string} props.title - The title of the input field
 * @param {Array} props.options - Array of the options to chose from
 * @param {Function} props.onEnter - Callback function when clicked on select
 */
export default class DropdownSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: null };
    }

    setValue = (event) => {
        this.setState({value: event.target.value});
    }

    click = () => {
        this.props.onEnter(this.state.value);
    }

    render() {
        const name = `dropdownSelect-${this.props.title}`;
        return (
            <div className='dropdownSelect'>
                <label htmlFor={name}>{this.props.title}</label>
                <select name={name} onChange={this.setValue} defaultValue="" required>
                    <option value="" disabled>{getTranslation("dropdown_select.select_option")}</option>
                    {this.props.options.map((option, i) => {
                        return <option key={i} value={option}>{option}</option>
                    })}
                </select>
                <button onClick={this.click}>{getTranslation("dropdown_select.select")}</button>
            </div>
        );
    }
}