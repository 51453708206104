import React from 'react';

export default class HostErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }
    
    static getDerivedStateFromError(error) {
        return { error };
    }
  
    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    nextSlide() {
        this.props.socket.emit('next-slide', (success) => {
            if (!success) {
                console.log("Cannot go to the next slide");
                return;
            }
        });
    }

    showResults() {
        this.props.socket.emit('show-results');
    }

    saveGame() {
        this.props.socket.emit('save-game');
    }
  
    render() {
        const {error} = this.state;
        if (error) {
            return (
                <div className='errorBoundary'>
                    <div className='errorBoundary__buttons'>
                        <button onClick={this.nextSlide.bind(this)}>next slide</button>
                        <button onClick={this.showResults.bind(this)}>show results</button>
                        <button onClick={this.saveGame.bind(this)}>save game file</button>
                    </div>
                    <br/>
                    <div className='errorBoundary__error'>
                        <p>{error.name}: {error.message}</p>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
  }
  