import React from 'react';

export default class Alert extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = { msg: null };
    }

    showAlert(msg) {
        clearTimeout(this.id);
        this.setState({ msg: null });

        setTimeout(() => {
            this.setState({ msg: msg });
        }, 0);

        this.id = setTimeout(() => {
            this.setState({ msg: null });
        }, 5000);
    }

    render() {
        return this.state.msg ? (
            <div className="alert">
                <img src='/assets/alert-icon.svg' alt="Exclamation mark in circle" />
                <p>{this.state.msg}</p>
            </div>
        ) : "";
    }
}