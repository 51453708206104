import React, { useState, useContext, useCallback, useEffect, createRef } from 'react';
import { SocketContext } from '../context/socket';
import { getTranslation } from './../lang'

import { Center } from '../components/Basic';
import Background, { BackgroundColor } from "../components/Background";
import PlayerSlide from '../components/PlayerSlide'
import InputBox from '../components/InputBox';
import Alert from '../components/Alert';

const ResultScreen = (props) => {
    return (props.correct) ? (
        <div className="player__result player__result--correct">
            <BackgroundColor color="#74F269" />
            <svg viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="159.5" cy="160.5" r="130.5" stroke="white" strokeWidth="10"/>
                <path d="M92 171.5L132.115 222.711C134.537 225.803 139.278 225.61 141.441 222.331L228.083 91" stroke="white" strokeWidth="20.4508" strokeLinecap="round"/>
            </svg>
            <div>
                <p className="player__result__points-gained">+{props.pointsGained}</p>
                <p>{getTranslation("messages.player.you_were_correct")}</p>
            </div>
        </div>
    ) : ((props.correct === false) ? (
        <div className="player__result player__result--wrong">
            <BackgroundColor color="#FF5252" />
            <svg viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="159.5" cy="160.5" r="130.5" stroke="white" strokeWidth="10"/>
                <path d="M92 93L227 228M227 93L92 228" stroke="white" strokeWidth="20.45" strokeLinecap="round"/>
            </svg>
            <div>
                <p>{getTranslation("messages.player.you_were_incorrect")}</p>
            </div>
        </div>
    ) : (
        <div className="player__result player__result--none">
            <BackgroundColor color="#FFBC58" />
            <svg viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="159.5" cy="160.5" r="130.5" stroke="white" strokeWidth="10"/>
                <path d="M156.81 199.127C150.553 199.127 145.395 193.979 146.152 187.768C146.704 183.243 147.56 179.38 148.72 176.178C150.754 170.562 154.905 164.337 161.171 157.501L177.162 141.021C183.998 133.29 187.416 124.99 187.416 116.119C187.416 107.574 185.178 100.901 180.702 96.0996C176.226 91.2168 169.716 88.7754 161.171 88.7754C152.87 88.7754 146.197 90.9727 141.151 95.3672C138.89 97.3366 137.136 99.6085 135.888 102.183C133.184 107.76 128.559 113.067 122.361 113.067V113.067C116.109 113.067 110.877 107.882 112.386 101.815C114.404 93.6979 118.622 86.8285 125.038 81.207C134.315 73.069 146.36 69 161.171 69C176.552 69 188.515 73.1504 197.06 81.4512C205.686 89.6706 209.999 100.982 209.999 115.387C209.999 129.628 203.407 143.666 190.224 157.501L176.918 170.685C172.908 175.134 170.251 180.901 168.948 187.984C167.829 194.065 162.994 199.127 156.81 199.127V199.127ZM144.447 237.823C144.447 234.161 145.546 231.109 147.743 228.668C150.022 226.145 153.358 224.884 157.753 224.884C162.147 224.884 165.484 226.145 167.763 228.668C170.041 231.109 171.181 234.161 171.181 237.823C171.181 241.485 170.041 244.537 167.763 246.979C165.484 249.339 162.147 250.519 157.753 250.519C153.358 250.519 150.022 249.339 147.743 246.979C145.546 244.537 144.447 241.485 144.447 237.823Z" fill="white"/>
            </svg>
            <div>
                <p>{getTranslation("messages.player.you_were_too_late")}</p>
            </div>
        </div>
    ));
}

const ScoreScreen = (props) => { 
    return (<div className="player__score">
        <BackgroundColor color="#70DADA" />
        <p>{getTranslation("messages.player.your_place")}</p>
        <p>{Number(props.score.place) + 1}</p>
        <svg viewBox="0 0 280 280" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M207.5 23.0866C238.507 40.9887 261.133 70.4752 270.4 105.059C279.667 139.644 274.816 176.493 256.913 207.5C239.011 238.507 209.525 261.133 174.941 270.4C140.356 279.667 103.507 274.816 72.5 256.913C41.4927 239.011 18.8668 209.525 9.60002 174.941C0.333198 140.356 5.18445 103.507 23.0865 72.5C40.9887 41.4927 70.4752 18.8669 105.059 9.60003C139.644 0.333198 176.493 5.18444 207.5 23.0865L207.5 23.0866Z" stroke="white" strokeWidth="10"/>
        </svg>
        <p>{getTranslation("messages.player.score")}</p>
        <p>{props.score.score}</p>
    </div>);
}

const Player = (props) => {
    const socket = useContext(SocketContext);
    const alertRef = createRef();

    const [appState, setAppState] = useState(0);
    const [name, setName] = useState("");
    const [pauseMessage, setPauseMessage] = useState("");
    const [slide, setSlide] = useState({});
    const [correct, setCorrect] = useState(false);
    const [score, setScore] = useState({});
    const [pointsGained, setPointsGained] = useState({});

    /* -------------------------------------------------------------------------- */
    /*                                  Functions                                 */
    /* -------------------------------------------------------------------------- */
    function join(name) {
        socket.emit('join', name, (response) => {
            if (response.error === "NO_NAME")
                alertRef.current.showAlert(getTranslation("messages.player.enter_a_name"));
            else if (response.error === "NAME_EXISTS")
                alertRef.current.showAlert(getTranslation("messages.player.name_already_in_use"));
            else if (response.error === "NAME_TOO_LONG")
                alertRef.current.showAlert(getTranslation("messages.player.name_too_long", response.maxLength));
            else if (response.uuid) {
                setCookie("uuid", response.uuid, 0.5);
                setName(name);
            }
            
        });
    }

    function waitForResult() {
        setPauseMessage(getTranslation("messages.player.wait_for_result"));
        setAppState(3);
    }

    function answer(answer) {
        socket.emit('answer-question', answer, (success) => {
            if (success) waitForResult();
        });
    }

    function setCookie(cName, cValue, expDays) {
        let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; secure=true; path=/"; // secure=false;
    }

    function getCookie(cName) {
        const name = cName + "=";
        const cDecoded = decodeURIComponent(document.cookie);
        let result;
        cDecoded.split('; ').forEach(val => {
            if (val.indexOf(name) === 0) result = val.substring(name.length);
        })
        return result;
    }    

    function isCookie(cName) {
        const name = cName + "=";
        const cDecoded = decodeURIComponent(document.cookie);
        let result;
        cDecoded.split('; ').forEach(val => {
            if (val.indexOf(name) === 0) result = true;
        })
        return result;
    }

    /* -------------------------------------------------------------------------- */
    /*                                Server events                               */
    /* -------------------------------------------------------------------------- */
    const connect = useCallback(() => {
        socket.emit('setRole', "player");
    }, [socket]);

    const gameStarted = useCallback(() => {
        if (isCookie("uuid")) {
            const uuid = getCookie("uuid");
        
            socket.emit('rejoin', uuid, (response) => {
                if (!response.success) {
                    setCookie("uuid", null, -1);
                    setAppState(2);
                }
                else setName(response.name);
            });
        }
        else setAppState(2);
    }, [socket]);

    const setState = useCallback((data) => {
        switch (data.state) {
            case 'nys':
                setAppState(1);
                break;
            case 'pause':
                setPauseMessage(data.message);
                setAppState(3);
                break;
            case 'slide':
                if (data.slide?.answered) waitForResult();
                else {
                    setSlide(data.slide);
                    setAppState(4);
                }
                break;
            case 'result':
                setCorrect(data.correct);
                setPointsGained(data.pointsGained);
                setAppState(5);
                break;
            case 'score':
                setScore({
                    score: data.score,
                    place: data.place,
                });
                setAppState(6);
                break;
            default:
                setAppState(-1);
        }
    }, []);

    const removeOptions = useCallback((removeOptions) => {
        let _slide = structuredClone(slide);
        _slide.removeOptions = removeOptions;
        setSlide(_slide);
    }, [slide]);

    const disconnect = useCallback(() => {
        setAppState(0);
    }, []);

    useEffect(() => {
        socket.on("connect", connect);
        socket.on("game-started", gameStarted);
        socket.on("set-state", setState);
        socket.on("remove-options", removeOptions);
        socket.on("disconnect", disconnect);

        return () => {
            socket.removeAllListeners();
        };
    }, [socket, connect, gameStarted, setState, disconnect, removeOptions]);

    /* -------------------------------------------------------------------------- */
    /*                                 Return DOM                                 */
    /* -------------------------------------------------------------------------- */
    let element;
    switch (appState) {
        case 0: 
            element = <div className="spinner"><BackgroundColor color="white" /></div>;
            break;
        case 1:
            element = <h1 style={{ "textAlign": "center", "padding": "20px" }}>{getTranslation("messages.general.no_games")}</h1>;
            break;
        case 2:
            element = <InputBox type="text" title="NAME" onEnter={join} autoSelect />; // TODO: error when username taken
            break;
        case 3:
            element = <h1 style={{ "textAlign": "center", "padding": "20px" }}>{pauseMessage}</h1>;
            break;
        case 4:
            element = <PlayerSlide slide={slide} onAnswer={answer} />;
            break;
        case 5:
            element = <ResultScreen correct={correct} pointsGained={pointsGained} />;
            break;
        case 6:
            element = <ScoreScreen score={score} />;
            break;
        default:
            element = (<h3>{getTranslation("messages.error.unknown")}</h3>)
    }

    return (
        <Background>
            <Alert ref={alertRef} />
            <Center>
                {(appState !== 0 && appState !== 1 && appState !== 2) && (
                    <div className="player__header">
                        <p>{name}</p>
                    </div>
                )}
                <div className="center" style={{height: "calc(100% - 40px)"}}>{element}</div>
            </Center>
        </Background>
    );
};

export default Player;