import React from 'react';

export default class Scoreboard extends React.Component {
    constructor(props) {
        super(props);
        this.COUNT_SPEED = 63;
        this.self = React.createRef();
        this.state = { scoreboard: this.props.scoreboard };
    }

    componentDidMount() {
        if (this.props.newScoreboard) {
            const delay = this.props.delay || 0;
            setTimeout(() => this.changeScoreboard(), delay);
        }
    }

    findPlayer(array, playerName) {
        let index = -1;
        array.forEach((player, i) => {
            if (player.name === playerName) index = i;
        });
        return index;
    }

    changeScoreboard() {
        const children = this.self.current.children;
        const topOffset = (children[0]) ? children[0].offsetTop : 0;
        let offset = 0;

        this.props.newScoreboard.forEach((player, i) => {
            const index = this.findPlayer(this.state.scoreboard, player.name);
            if (index === -1) return;
            
            this.moveElement(children[index], i, topOffset);
            this.countScore(children[index], this.state.scoreboard[index].score, this.props.newScoreboard[i].score, this.state.scoreboard[index].score);

            setTimeout(() => {
                if (i !== 0 && player.score === this.props.newScoreboard[i-1].score) offset++;
                children[index].children[0].style = "opacity: 1;";
                children[index].children[0].innerText = (i+1-offset)+'.';
            }, 895);
        });
    }

    moveElement(element, index, offset) {
        let height = element.clientHeight + Number((element.currentStyle || window.getComputedStyle(element)).marginBottom.replace(/[^0-9 ]/g, ""));
        let distance = (offset + index * height) - element.offsetTop;
        if (distance === 0) return;

        element.style.transform = `translateY(${distance}px)`;
        element.children[0].style.opacity = "0";
    }

    countScore(element, start, target, count) {
        if (count === target) return;
        const inc = (target - start) / this.COUNT_SPEED;

        if (count < target) {
            element.children[2].innerText = Math.round(count + inc);
            setTimeout(() => this.countScore(element, start, target, count + inc), 14); // 1 -> 14
        }
        else element.children[2].innerText = target;
    }

    render() {
        let offset = 0;
        return (
            <div className="scoreboard" ref={this.self}>
                {this.state.scoreboard.map((player, i) => {
                    if (i !== 0 && player.score === this.state.scoreboard[i-1].score) offset++;
                    return <div key={i} className="scoreboard__item">
                                <p>{(i+1-offset)+'.'}</p>
                                <p>{player.name}</p>
                                <p>{player.score}</p>
                            </div>;
                })}
            </div>
        );
    }
}
