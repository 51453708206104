import React from 'react';

export class Center extends React.Component {
    render() {
        return <div className='center'>{this.props.children}</div>
    }
}

export class QRCode extends React.Component {
    constructor(props) {
        super(props);
        
        let parameters = `size=${this.props.size || "250"}&bgcolor=255-255-255&color=0-0-0&qzone=2&data=${this.props.data || "error"}`;
        this.src = `https://api.qrserver.com/v1/create-qr-code/?${parameters}`;
    }

    render() {
        return (
            <img src={this.src} alt={this.props.data} />
        );
    }
}