import React from 'react';
import { Center } from '../components/Basic';
import Background from "../components/Background";
import Countdown from '../components/Countdown';

const Test = (props) => {


    return <Background><Center><Countdown timeLimit={60} type="sidebar" /></Center></Background>;
};

export default Test;